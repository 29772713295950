<template>
  <div class="wrapper">
    <!-- <div class="main-panel"> -->
      <Navbar />
      <dashboard-content @click.native="toggleSidebar" > </dashboard-content>
    <!-- </div> -->
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import Navbar from '@/layout/navbar.vue';
export default {
  components: {
    TopNavbar,
    // ContentFooter,
    DashboardContent,
    Navbar
  },
  data() {
    return {
      showSidebar: false,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  created() {
    this.$on("hide-sidebar", (val) => {
      console.log("hide-sidebar", val);
      this.showSidebar = val;
    });
  },
};
</script>
