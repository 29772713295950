<template>
  <table class="table-container table-mobile" :class="tableClass">
    <thead :class="theadClasses" class="th-mobile">
      <tr>
        <slot name="columns">
          <th>Servidor</th>
          <th>Usuario</th>
          <th>Senha</th>
          <th>Diretório</th>
          <th>Domínio</th>
          <th>Actions</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <template v-for="(column, index) in columns">
            <td :key="index" v-if="hasValue(item, column)">
              {{ column === "ftp_password" ? "************" : itemValue(item, column) }}
            </td>
          </template>
          <td style="display: flex">
            <button class="action-btn edit" @click="editItem(item)">
              <Edit class="action-icon"/>
            </button>
            <button class="action-btn delete" @click="removeItem(item)">
              <Trash class="action-icon"/>
            </button>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
import {
  Edit,
  Trash
} from 'lucide-vue';

export default {
  name: "base-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  components: {
    Edit,
    Trash
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    editItem(item) {
      this.$emit("edit", item);
    },
    removeItem(item) {
      this.$emit("remove", item);
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    valuePassword(item, column) {
      return item[column.toLowerCase()].replace(/./g, "*");
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
  },
};
</script>
<style scoped>

.table-row:hover {
  background-color: rgba(255, 255, 255, 0.02);
}
.table-container th {
  text-align: left;
  padding: 16px;
  background-color: #141922;
  color: #999;
  font-weight: 500;
}

.table-container td {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.action-btn.edit {
  color: #FFB800;
}

.action-btn.edit:hover {
  background-color: rgba(255, 184, 0, 0.1);
}

.action-btn.delete {
  color: #FF4747;
}

.action-btn.delete:hover {
  background-color: rgba(255, 71, 71, 0.1);
}

.action-icon {
  width: 16px;
  height: 16px;
}

.action-btn {
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-remove {
  color: red;
  background-color: rgba(255, 255, 255, 0) important;
  border-radius: 5px;
  width: 30px;
  margin: 5px;
  cursor: pointer;
}

.btn-edit {
  color: rgb(10, 75, 172);
  background-color: rgba(255, 255, 255, 0) important;
  border-radius: 5px;
  width: 30px;
  margin: 5px;
  cursor: pointer;
}
@media (max-width: 450px) {
  .table-container {
    width: 300px
  }
  .th-mobile {
    width: 100px;
  }
}
</style>
