<template>
    <div class="app-container">
      <!-- Sidebar -->
      <aside :class="['sidebar2', { 'open': isOpen }]">
        <div class="sidebar-header">
          <img v-if="isOpen" :src="logoIacopi" alt="Iacopi" class="logo logoMobile" />
        </div>
  
        <nav class="sidebar-nav">
          <a v-for="item in menuItems" :key="item.name" 
          :class="['nav-item', { 'active': activeItem.name === item.name ? true : false}]" style="padding: 10px; cursor: pointer;" @click="openPage(item), activeItem = item">
            <component :is="item.icon" class="nav-icon" style="margin-right: 10px;" />
            <span v-if="isOpen" class="nav-text">{{ item.name }}</span>
          </a>
        </nav>
  
        <nav style="margin-top: 20%;">
          <div v-if="isOpen" class="sidebar-footer">
            <div class="host-vsl" style="cursor: pointer;" @click="openMembers">
              <p class="host-vsl-text">Área de Membros</p>
              <p class="host-vsl-subtext">Acesse todo o conteúdo do IACOPI</p>
            </div>
          </div>
          <button class="menu-toggle" @click="toggleSidebar">
              <ArrowLeft class="arrow" v-if="isOpen" />
              <ArrowRight class="arrow" v-else />
          </button>
        </nav>
        

        <MenuAccount 
          v-if="isDropdownOpen"
          class="dropdown-menu2" 
          @logout="logout"
          @openConfig="openAccount"/>


        <div class="user-footer">
          <div class="user-area" @click="isDropdownOpen=!isDropdownOpen">
            <button class="user-pic">
                <span>{{ getInitialsFromName() }}</span>
            </button>
            <div v-if="isOpen" class="user-info">
                <span class="ellipsis">{{ getNameAndSecondName() }}</span>
              <span class="ellipsis">eltonjhsouza@gmail.com</span>
            </div>
            <div>
              <ChevronsUpDown />
            </div>
          </div>
        </div>
      </aside>
      
      <aside class="sidbar-mobile" ref="sidebarMobile">
        <div class="sidebar-header">
          <img :src="logoIacopi" alt="Iacopi" class="logo logoMobile" />
        </div>
  
        <nav class="sidebar-nav">
          <a v-for="item in menuItems.filter(item => item.icon !== 'Settings')" :key="item.name" 
            :class="['nav-item', { 'active': activeItem.name === item.name ? true : false}]" style="padding: 10px; cursor: pointer;" @click="openPage(item), activeItem = item">
            <component :is="item.icon" class="nav-icon" style="margin-right: 10px;" />
            <span v-if="isOpenMobile" class="nav-text">{{ item.name }}</span>
          </a>
        </nav>
        <button class="menu-toggle-mobile" @click="toggleSidebarMobile">
            <ArrowLeft class="arrow" v-if="isOpenMobile" />
            <ArrowRight class="arrow" v-else />
        </button>

        <div class="user-footer-mobile">
          <div class="user-area" @click="isDropdownOpen=!isDropdownOpen">
            <button class="user-pic">
                <span>{{ getInitialsFromName() }}</span>
            </button>
            <div v-if="isOpenMobile" class="user-info">
                <span class="ellipsis">{{ getNameAndSecondName() }}</span>
              <span class="ellipsis">eltonjhsouza@gmail.com</span>
            </div>
            <div>
              <ChevronsUpDown />
            </div>
          </div>
        </div>
      </aside>

      <MenuAccount 
          v-if="isDropdownOpen"
          class="dropdown-menu2 menu-mobile" 
          @logout="logout"
          @openConfig="openAccount"/>

      <main class="main-content">
        <header class="top-bar">
          <Menu class="menuIcon" @click="toggleSidebarMobile" />
          <div class="top-bar-content">
            <img :src="logoIacopi" alt="Iacopi" class="top-logo" />
          </div>
        </header>
        <dashboard-content class="flex" ></dashboard-content>
      </main>
    </div>
  </template>
  
  <script>
  import MenuAccount from '@/components/Account/MenuAccount.vue';
  import logoiacopi from '@/assets/logo-iacopi.svg';
  import imgMembers from '@/assets/members.png';
  import DashboardContent from "./Content.vue";

  import {
    LayoutDashboard,
    Copy,
    Settings,
    FileText,
    BookOpen,
    Calendar,
    Menu,
    ArrowLeft,
    ArrowRight,
    FileCog,
    ChevronsUpDown
  } from 'lucide-vue';
  
  export default {
    name: 'Navbar',
    components: {
      LayoutDashboard,
      Copy,
      Settings,
      FileText,
      BookOpen,
      Calendar,
      DashboardContent,
      Menu,
      ArrowLeft,
      ArrowRight,
      FileCog,
      ChevronsUpDown,
      MenuAccount,
    },
    data() {
      return {
        isOpenMobile: false,
        isDropdownOpen: false,
        isOpen: true,
        activeItem: {
        },
        user: {
          name: '',
          email: '',
          status: '',
          id: 0
        },
        previousRoute: [],
        menuItems: [
          { name: 'Início', icon: 'LayoutDashboard', path: '/', active: false },
          { name: 'Páginas', icon: 'Copy', path: '/pages', active: false },
          { name: 'Page Builder', icon: 'Settings', path: '/editor', active: false },
          { name: 'Contas FTP', icon: 'FileCog', path: '/ftpaccounts', active: false },
          // { name: 'Modelos de Páginas', icon: 'FileText', path: '/pages-template', active: false },
          { name: 'Tutoriais', icon: 'BookOpen', path: '/learn', active: false },
          // { name: 'O que está por vir', icon: 'Calendar', path: '/new', active: false },
        ],
        logoIacopi: logoiacopi,
        imgMembers: imgMembers
      };
    },
    methods: {
      getInitialsFromName() {
        return this.user.name.split(' ').slice(0, 2).map(n => n[0]).join('');
      },
      getNameAndSecondName() {
        return this.user.name.split(' ').slice(0, 2).join(' ');
      },
      logout() {
        this.$router.push({ path: '/login' });
      },
      openSettings() {
        this.$router.push({ path: '/settings' });
      },
      toggleSidebar() {
        this.isOpen = !this.isOpen;
      },
      toggleSidebarMobile() {
        this.isOpenMobile = !this.isOpenMobile;
        const sidebarMobile = this.$refs.sidebarMobile;
        if (!this.isOpenMobile) {
          document.querySelector('.sidbar-mobile').style.display = 'none';
        } else {
          // document.querySelector('.sidbar-mobile').style.display = 'block';
          if (sidebarMobile && !sidebarMobile.contains(event.target)) {
            console.log('isOpenMobile', sidebarMobile);
            if (sidebarMobile.style.display == 'none' || !sidebarMobile.hasAttribute('style')) {
                console.log('Entrou no IF', sidebarMobile);
                sidebarMobile.style.display = 'block'; 
            }
          }
        }
      },
      openMembers() {
        window.open('https://iacopi.site/', '_blank');
      },
      openPage(item) {
        this.activeItem = item;
        this.$router.push({ path: item.path });
      },
      logout() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('ftpaccounts');
        localStorage.removeItem('user');
        sessionStorage.removeItem('authToken');
        localStorage.removeItem('user');

        this.$router.push({ name: 'login' });
      },
      openAccount() {
        this.$router.push({ path: '/my-account' });
      },
      handleClickOutside(event) {
        const sidebarMobile = this.$refs.sidebarMobile;
        if (sidebarMobile && !sidebarMobile.contains(event.target)) {
          if (sidebarMobile.style.display !== 'none' && !this.isOpenMobile) {
              sidebarMobile.style.display = 'none'; 
          }
        }
      },
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    mounted() {
      this.$root.$on('update-user', (user) => {
        console.log("USer", user);
        this.user.name = `${user.name} ${user.las_name}`;
        this.user.email = user.email;
        this.user.id = user.id;
        this.user.status = user.status;
      });

      let user = JSON.parse(sessionStorage.getItem('user'))
      if (user) {
        this.user.name = `${user.name} ${user.las_name}`;
        this.user.email = user.email;
        this.user.id = user.id;
        this.user.status = user.status;
      }
      if (this.$router.currentRoute.path) {
        this.activeItem = this.menuItems.find(item => item.path === this.$router.currentRoute.path) || this.menuItems[0];
      }
    }
  };
  </script>
  
  <style scoped>
  .app-container {
    display: flex;
    min-height: 100vh;
    background-color: #0B0E13;
    color: white;
  }
  
  .sidebar2 {
    width: 300px;
    background-color: #0F131A;
    padding: 15px;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease-in-out;
    position: relative;
  }
  
  .sidebar2:not(.open) {
    width: 80px;
    padding: 24px 10px;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #999;
    font-size: 24px;
    cursor: pointer;
  }
  
  .toggle-btn:hover {
    color: white;
  }
  
  .sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    color: #999;
    transition: background-color 0.2s;
  }
  
  .nav-item:hover {
    background-color: #1A1F2B;
  }
  
  .nav-item.active {
    background-color: rgba(0, 240, 255, 0.1);
    color: #00F0FF;
  }

  .active {
    font-weight: bold;
    color: blue;
  }
  
  /* Ajustes para esconder o texto quando retraído */
  .sidebar2:not(.open) .nav-text {
    display: none;
  }
  
  .sidebar2:not(.open) .nav-item {
    justify-content: center;
  }
  
  .sidebar2:not(.open) .nav-icon {
    margin-right: 0;
  }
  
  /* Oculta a seção do rodapé quando o menu está fechado */
  .sidebar2:not(.open) .sidebar-footer {
    display: none;
  }
  
  .sidebar2:not(.open) .user-footer {
    margin-left: -14%;
  }

  .sidebar2:not(.open) .dropdown-menu2 {
    position: relative !important;
    bottom: -44px !important;
  }

  .sidebar-footer {
    margin-top: auto;
  }
  .user-pic {
    background-color: #2b4279;
    border-radius: 50%;
    border: none;
    color: white;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  .user-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #1A1F2B;
    border-radius: 10px;
    height: 70px;
    padding: 10px;
    margin: 5px;
  }
  .user-area:hover {
    background-color: #121722;
    border-radius: 10px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    font-size: 0.775rem;
    font-weight: 500;
    margin-left: 10px;
  }

  .user-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #1A1F2B; */
    position: absolute;
    border-radius: 10px;
    bottom: 5px;
  }

  .host-vsl {
    background-color: #1A1F2B;
    padding: 16px;
    border-radius: 8px;
  }
  
  .host-vsl-logo {
    height: 32px;
    margin-bottom: 8px;
  }
  
  .host-vsl-text {
    font-size: 14px;
    color: #999;
  }
  
  .host-vsl-subtext {
    font-size: 12px;
    color: #00F0FF;
  }
  
  .main-content {
    flex: 1;
    transition: margin-left 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Estilo do botão de retrair menu */
.menu-toggle {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;
}

.top-bar {
  display: none;
}

.menu-toggle:hover {
  background-color: #555;
}

.arrow {
  color: white;
}

.menuIcon {
 display: none;
}

.sidbar-mobile {
  display: none;
}

.menu-mobile {
    display: none;
  }

@media (max-width: 450px) {
  .main-content {
    flex: 1;
    flex-direction: column;
  }
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    margin-top: 10px;
    padding: 20px;
  }

  .menuIcon {
    width: 50px;
    display: block;
    cursor: pointer;
  }

  .sidebar2 {
    display: none;
    position: sticky;
    width: 50vw;
    z-index: 1000;
  }
  .logoMobile {
    display: block;
    padding: 10px;
    margin-top: 25px;
  }

  .sidbar-mobile {
    display: block;
  }

  .sidebar-footer {
    margin-top: 0;
  }

  .user-info {
    display: none;
  }
 
  .sidbar-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 200px;
    background-color: #151b2b;
    border-right: 1px solid #2a2a2a;
    z-index: 50;
    transition: transform 0.3s ease;
  }

  .menu-toggle-mobile {
    position: absolute;
    right: -11px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: #b1a6a6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
  }

  .user-footer-mobile {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 10px;
    bottom: 5px;
    margin-left: 3px;
  }

  .user-pic {
    background-color: #2b4279;
    border-radius: 50%;
    border: none;
    color: white;
    font-size: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .user-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: #1A1F2B;
    border-radius: 10px;
    height: 70px;
    padding: 2px;
    margin: 2px;
  }
  .user-area:hover {
    background-color: #121722;
    border-radius: 10px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    font-size: 0.600rem;
    font-weight: 500;
    margin-left: 10px;
  }

  .dropdown-menu2 {
    position: absolute;
    left: 60px;
    bottom: 35vh;
    background-color: #151b2b;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 1000;
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }

   .sidbar-mobile:not(.open) .dropdown-menu2 {
    position: relative !important;
    bottom: -44px !important;
  }

}
</style>
  