import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import VuePlyr from '@skjnldsv/vue-plyr';
import '@skjnldsv/vue-plyr/dist/vue-plyr.css';

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';

const tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";
document.head.appendChild(tag);

// Configuração do VuePlyr
Vue.use(VuePlyr, {
  plyr: {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
  }
});

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
new Vue({
  router,
  i18n,
  grapesjs,
  render: (h) => h(App),
}).$mount("#app");
