import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import AppLayout from "@/layout/dashboard/appLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Index.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Settings = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Settings.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const Products = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Products.vue");

const EditorLight = () =>
  import("@/pages/LightEditor/Index.vue");

const Editor = () =>
  import("@/pages/Editor/Index.vue");

const Login = () =>
  import("@/pages/Login/Login.vue");

const ResetPassword = () =>
  import("@/pages/Login/ResetPassword.vue");

const PagesIndex = () =>
  import("@/pages/Sites/index.vue");

const Tutorials = () =>
  import("@/pages/Tutoriais/index.vue");

const Ftp = () =>
  import("@/pages/FTP/FTPSettings.vue");

const Templates = () =>
  import("@/pages/Templates/index.vue");

const UserAccount = () =>
  import("@/components/Account/FormAccount.vue");

const routes = [
  {
    path: "/",
    name: "app",
    redirect: "/app",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pages",
        name: "pages",
        component: PagesIndex,
      },
      {
        path: "/learn",
        name: "learn",
        component: Tutorials,
      },
      {
        path: "/ftpaccounts",
        name: "ftpaccounts",
        component: Ftp,
      },
      {
        path: "/pages-template",
        name: "templates",
        component: Templates,
      },
      {
        path: "/my-account",
        name: "my-account",
        component: UserAccount,
      },
    ]
  },
  {
    path: "/editor",
    name: "editor",
    component: Editor,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/editor-lite",
    name: "editor-lite",
    component: EditorLight,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/reset-password",
    name: "reset",
    component: ResetPassword,
  },
  {
    path: "/app1",
    component: DashboardLayout,
    // redirect: "/app",  
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/config",
        name: "config",
        component: Settings,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "/icons",
        name: "icons",
        component: Icons,
      },
      {
        path: "/maps",
        name: "maps",
        component: Maps,
      },
      {
        path: "/typography",
        name: "typography",
        component: Typography,
      },
    ],
  },
  {
    path: "/products",
    name: "products",
    component: Products,
  },
  // {
  //   path: "/pages",
  //   name: "pages",
  //   component: PagesIndex,
  // },
  { 
    path: "/:pathMatch(.*)*", 
    name: "not-found", 
    component: NotFound 
  },
];

export default routes;
