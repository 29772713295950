<template>
      <div class="dropdown-menu2">
        <div class="btnMenu" @click="$emit('openConfig')">Minha Conta</div>
        <div class="btnMenu" @click="$emit('logout')" >Sair</div>
      </div>
</template>
<script>
export default {
  name: 'MenuAccount',
  data() {
    return {
    
    };
  },
  methods: {
    logout () {
      localStorage.removeItem('authToken');
      sessionStorage.removeItem('authToken');
      this.$router.push({ name: 'login' });
    },
    openConfig() {
      // this.$router.push({ name: 'config' });
    },
  }
}
</script>
<style scoped>
.dropdown-menu2 {
    background-color: #fff;
    position: absolute;
    bottom: 60px;
    right: 0;
    background: #1A1F2B;
    border-radius: 8px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); */
    padding: 10px;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    z-index: 1000;
    box-shadow: 0px 5px 20px #293146; 
    border: solid 1px #2b2b2c;
  }

  .btnMenu {
    background-color: #1A1F2B !important;
    color: white;
    padding: 10px;
    border-radius: 8px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;;
    border: solid 1px #13151d;
    
  }

  .btnMenu:hover {
    background-color: #5f3c06 !important;
  }
</style>