<template>
    <div class="app-container">
      <!-- Sidebar -->
      <aside :class="['sidebar2', { 'open': isOpen }]">
        <div class="sidebar-header">
          <h1 class="logo">Replic</h1>
          <button @click="toggleSidebar" class="toggle-btn desktop-only">
            <span v-if="isOpen">&#x2190;</span>
            <span v-else>&#x2192;</span>
          </button>
        </div>
        
        <nav class="sidebar-nav">
          <a v-for="item in menuItems" :key="item.name" :href="item.href" :class="['nav-item', { 'active': item.active }]">
            <component :is="item.icon" class="nav-icon" />
            <span class="nav-text">{{ item.name }}</span>
          </a>
        </nav>
  
        <div class="sidebar-footer">
          <div class="host-vsl">
            <img src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-bUh07urDK9MvhWqcjlJzT5P57BuvX6.png" alt="HostVSL" class="host-vsl-logo" />
            <p class="host-vsl-text">Player de vídeo</p>
            <p class="host-vsl-subtext">Hospede seus vídeos aqui</p>
          </div>
        </div>
      </aside>
  
      <!-- Mobile Toggle Button -->
      <button @click="toggleSidebar" class="toggle-btn mobile-only">
        <span v-if="isOpen">&#x2715;</span>
        <span v-else>&#x2630;</span>
      </button>
  
      <!-- Overlay for mobile -->
      <div v-if="isOpen" class="overlay mobile-only" @click="toggleSidebar"></div>
  
      <!-- Main Content -->
      <main class="main-content">
        <slot></slot>
      </main>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { 
    LayoutDashboard, 
    Copy, 
    Settings, 
    FileText, 
    BookOpen, 
    Calendar
  } from 'lucide-vue'
  
  export default {
    name: 'Navbar',
    components: {
      LayoutDashboard,
      Copy,
      Settings,
      FileText,
      BookOpen,
      Calendar
    },
    setup() {
      const isOpen = ref(true)
      
      const menuItems = [
        { name: 'Dashboard', icon: 'LayoutDashboard', href: '#', active: true },
        { name: 'Replicar', icon: 'Copy', href: '#', active: false },
        { name: 'Replic Builder', icon: 'Settings', href: '#', active: false },
        { name: 'Modelos', icon: 'FileText', href: '#', active: false },
        { name: 'Tutoriais', icon: 'BookOpen', href: '#', active: false },
        { name: 'O que está por vir', icon: 'Calendar', href: '#', active: false },
      ]
  
      const toggleSidebar = () => {
        isOpen.value = !isOpen.value
      }
  
      return {
        isOpen,
        menuItems,
        toggleSidebar
      }
    }
  }
  </script>
  
  <style scoped>
  .app-container {
    display: flex;
    min-height: 100vh;
    background-color: #0B0E13;
    color: white;
  }
  
  .sidebar2 {
    width: 256px;
    background-color: #0F131A !important;
    padding: 24px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
  }
  
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #999;
    font-size: 24px;
    cursor: pointer;
  }
  
  .toggle-btn:hover {
    color: white;
  }
  
  .sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: none;
    color: #999;
    transition: background-color 0.2s;
  }
  
  .nav-item:hover {
    background-color: #1A1F2B;
  }
  
  .nav-item.active {
    background-color: rgba(0, 240, 255, 0.1);
    color: #00F0FF;
  }
  
  .nav-icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  
  .sidebar-footer {
    margin-top: auto;
  }
  
  .host-vsl {
    background-color: #1A1F2B;
    padding: 16px;
    border-radius: 8px;
  }
  
  .host-vsl-logo {
    height: 32px;
    margin-bottom: 8px;
  }
  
  .host-vsl-text {
    font-size: 14px;
    color: #999;
  }
  
  .host-vsl-subtext {
    font-size: 12px;
    color: #00F0FF;
  }
  
  .main-content {
    flex: 1;
    padding: 32px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  
  @media (max-width: 768px) {
    .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 20;
      transform: translateX(-100%);
    }
  
    .sidebar.open {
      transform: translateX(0);
    }
  
    .toggle-btn.mobile-only {
      position: fixed;
      bottom: 16px;
      right: 16px;
      background-color: #00F0FF;
      color: black;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 30;
    }
  
    .desktop-only {
      display: none;
    }
  }
  
  @media (min-width: 769px) {
    .mobile-only {
      display: none;
    }
  
    .sidebar:not(.open) {
      width: 80px;
    }
  
    .sidebar:not(.open) .logo,
    .sidebar:not(.open) .nav-text,
    .sidebar:not(.open) .host-vsl {
      display: none;
    }
  
    .sidebar:not(.open) .nav-item {
      justify-content: center;
    }
  
    .sidebar:not(.open) .nav-icon {
      margin-right: 0;
    }
  
    .main-content {
      /* margin-left: 80px; */
      transition: margin-left 0.3s ease-in-out;
    }
  
    .sidebar.open + .main-content {
      /* margin-left: 256px; */
    }
  }
  </style>